.movie-list {
  max-height: 600px; /* Set a maximum height for vertical scrolling */
  overflow-y: scroll; /* Enable vertical scrolling */
}

.movie-title {
  margin-bottom: 10px; /* Add spacing between title and "Watch Now" link */
}


.movie-card {
  flex: 1;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
}

.movie-card-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.watch-link {
  margin-top: 10px;
  text-decoration: none;
  background-color: #007bff;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}

.watch-link:hover {
  background-color: #0056b3;
}


