/* Shared styles */
.menuContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto;
  flex-direction: row;
  min-height: 100px;
  margin: 10px;
}

.tile {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  color: #333;
  text-decoration: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 24px;
  height: 160px;
}

.submenuContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.backButton {
  background-color: #333;
  color: #fff;
  padding: 20px;
}

.backLink {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.categoryTiles {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px;
}

.categoryTile {
  flex: 0 0 auto;
  min-width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e0e0e0;
  color: #333;
  text-decoration: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 24px;
}

.categoryDataContainer {
  padding: 20px;
  background-color: #f4f4f4;
  min-height: 100vh;
  overflow-y: auto;
}

.categoryMessage {
  background-color: #f0f0f0;
  margin: 8px;
  padding: 12px;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.thirdPageContainer {
  padding: 20px;
  background-color: #f4f4f4;
  min-height: 100vh;
  overflow-y: auto;
}

.thirdPageContent {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px;
}

.bottomNavBar {
  /* Bottom navigation bar styles */
}
